<template>
  <form class="source">
    <!-- Name -->
    <v-text-field
      v-model.trim="$v.form.name.$model"
      class="required-field"
      :label="$t('forms.fieldName')"
      :hint="$t('field.name.hint')"
      persistent-hint
      :error-messages="nameErrors"
      @input="setNameInput"/>

    <!-- Index -->
    <v-text-field
      v-model.trim="$v.form.index.$model"
      class="required-field"
      :label="$t('field.index.label')"
      :hint="$t('field.index.hint')"
      persistent-hint
      :error-messages="indexErrors"/>

    <!-- URLs -->
    <v-combobox
      v-model="$v.form.urls.$model"
      :class="{ 'required-field': requireUrls }"
      :label="$t('field.urls.label')"
      :hint="$t('field.urls.hint')"
      persistent-hint
      :error-messages="urlsErrors"
      hide-no-data
      append-icon=""
      multiple
      small-chips>
      <template #selection="{ item, parent, selected }">
        <v-chip
          :input-value="selected"
          label
          small>
          <span class="pr-2">{{ item }}</span>
          <v-icon
            small
            @click="parent.selectItem(item)">
            close
          </v-icon>
        </v-chip>
      </template>
    </v-combobox>

    <!-- Mapping -->
    <fieldset class="fieldset">
      <legend>
        <span class="fieldset__title">{{ $t('section.mapping.title') }}</span>
        <p class="fieldset__help-text">
          {{ $t('section.mapping.help') }}
        </p>
      </legend>

      <v-text-field
        v-model="$v.form.mapping.host.$model"
        class="required-field"
        :label="$t('field.host.label')"
        :hint="$t('field.host.hint')"
        persistent-hint
        :error-messages="hostErrors"/>

      <v-text-field
        v-model="$v.form.mapping.timestamp.$model"
        class="required-field"
        :label="$t('field.timestamp.label')"
        :hint="$t('field.timestamp.hint')"
        persistent-hint
        :error-messages="timestampErrors"/>

      <v-text-field
        v-model="$v.form.mapping.message.$model"
        class="required-field"
        :label="$t('field.message.label')"
        :hint="$t('field.message.hint')"
        persistent-hint
        :error-messages="messageErrors"/>
    </fieldset>

    <!-- Prefilters -->
    <form
      class="fieldset"
      @submit.prevent="addPrefilter">
      <legend>
        <span class="fieldset__title">{{ $t('section.prefilters.title') }}</span>
        <p class="fieldset__help-text">
          {{ $t('section.prefilters.help') }}
        </p>
      </legend>

      <v-slide-y-transition group>
        <div
          v-for="(val, key) in form.prefilters"
          :key="`prefilters-${key}`"
          class="d-flex align-center">
          <v-text-field
            :value="key"
            disabled
            readonly
            :label="$t('untranslated.key')"/>

          <v-text-field
            :value="val"
            disabled
            readonly
            class="ml-6"
            :label="$t('forms.value')"/>

          <CyButton
            class="ml-6"
            variant="tertiary"
            theme="grey"
            icon-only
            icon="delete"
            @click="removePrefilter(key)"/>
        </div>
      </v-slide-y-transition>

      <!-- Add prefilter -->
      <div class="d-flex align-center">
        <v-text-field
          ref="newPrefilterKey"
          v-model="$v.newPrefilter.key.$model"
          :label="$t('untranslated.key')"
          :error-messages="newPrefilterKeyErrors"
          required
          class="required-field"/>

        <v-text-field
          v-model="$v.newPrefilter.value.$model"
          :label="$t('forms.value')"
          :error-messages="newPrefilterValueErrors"
          required
          class="required-field ml-6"/>

        <CyButton
          class="ml-6"
          variant="tertiary"
          theme="grey"
          icon-only
          icon="add"
          type="submit"/>
      </div>
    </form>

    <CyButton
      class="mt-4"
      variant="secondary"
      theme="error"
      @click="$emit('remove')">
      {{ $t('deleteSource') }}
    </CyButton>
  </form>
</template>

<script>
import REGEX from '@/utils/config/regex'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsElasticSearchSource',
  props: {
    restrictedNames: {
      type: Array,
      default: () => [],
    },
    requireUrls: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: ({ value }) => ({
    form: {
      name: _.get(value, 'name', ''),
      index: _.get(value, 'index', ''),
      urls: _.get(value, 'urls', []),
      mapping: {
        host: _.get(value, 'mapping.host', ''),
        timestamp: _.get(value, 'mapping.timestamp', ''),
        message: _.get(value, 'mapping.message', ''),
      },
      prefilters: _.get(value, 'prefilters', {}),
    },
    newPrefilter: {
      key: '',
      value: '',
    },
  }),
  validations () {
    return {
      form: {
        name: {
          required,
          unique: this.isNameUnique,
          minLength: minLength(3),
          alphaNumeric: (name) => REGEX.PROJECT_NAME.test(name),
        },
        index: { required },
        urls: {
          hasSchema: (urls) => urls
            .map((url) => REGEX.URL_SCHEME.test(url))
            .every((isValid) => isValid),
          required: (urls) => { return !this.requireUrls || !_.isEmpty(urls) },
        },
        mapping: {
          host: {
            required,
            noComma: this.hasNoComma,
          },
          timestamp: {
            required,
            noComma: this.hasNoComma,
          },
          message: { required },
        },
      },
      newPrefilter: {
        key: {
          required,
          unique: (key) => !_.has(this.form.prefilters, key),
        },
        value: {
          required,
        },
      },
    }
  },
  computed: {
    nameErrors () {
      const errors = []
      const { $dirty, required, unique, alphaNumeric, minLength } = this.$v.form.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!unique) errors.push(this.$t('field.name.mustBeUnique'))
      if (!minLength) errors.push(this.$t('forms.fieldMinLength', { number: 3 }))
      if (!alphaNumeric) errors.push(this.$t('forms.fieldNotAlphaNum'))
      return errors
    },
    indexErrors () {
      const errors = []
      const { $dirty, required } = this.$v.form.index
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    urlsErrors () {
      const errors = []
      const { $dirty, hasSchema, required } = this.$v.form.urls
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('field.urls.required'))
      if (!hasSchema) errors.push(this.$t('field.urls.mustHaveScheme'))
      return errors
    },
    hostErrors () {
      const errors = []
      const { $dirty, required, noComma } = this.$v.form.mapping.host
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!noComma) errors.push(this.$t('field.host.cannotContainComma'))
      return errors
    },
    timestampErrors () {
      const errors = []
      const { $dirty, required, noComma } = this.$v.form.mapping.timestamp
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!noComma) errors.push(this.$t('field.timestamp.cannotContainComma'))
      return errors
    },
    messageErrors () {
      const errors = []
      const { $dirty, required } = this.$v.form.mapping.message
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    newPrefilterKeyErrors () {
      const errors = []
      const { $dirty, required, unique } = this.$v.newPrefilter.key
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!unique) errors.push(this.$t('field.key.mustBeUnique'))
      return errors
    },
    newPrefilterValueErrors () {
      const errors = []
      const { $dirty, required } = this.$v.newPrefilter.value
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  watch: {
    form: {
      deep: true,
      handler (form) {
        this.$emit('input', form)
      },
    },
  },
  methods: {
    addPrefilter () {
      this.$v.newPrefilter.$touch()
      if (this.$v.newPrefilter.$invalid) return

      const { key, value } = this.newPrefilter
      this.$set(this.form.prefilters, key, value)
      this.clearNewPrefilter()
      this.$refs.newPrefilterKey.focus()
    },
    removePrefilter (key) {
      if (_.get(this.form.prefilters, key)) this.$delete(this.form.prefilters, key)
    },
    clearNewPrefilter () {
      this.newPrefilter.key = ''
      this.newPrefilter.value = ''
      this.$v.newPrefilter.$reset()
    },
    isNameUnique (name) {
      return !_.includes(this.restrictedNames, name)
    },
    hasNoComma (host) {
      return !host.includes(',')
    },
    setNameInput (name) {
      this.$v.form.name.$model = name.trim().split(' ').join('_').toLowerCase()
    },
  },
  i18n: {
    messages: {
      en: {
        deleteSource: 'Delete source',
        field: {
          host: {
            cannotContainComma: 'The host cannot contain any commas',
            hint: 'The host key of your log entry.',
            label: 'Host',
          },
          index: {
            hint: 'Name of the index and eventually type. (e.g. app-logs or app-logs/type-logs)',
            label: 'Index',
          },
          key: {
            mustBeUnique: 'This key already exists',
          },
          message: {
            hint: `The message key of your log entry, can be composed of one or several keys comma-separated (e.g.: 'message,status')`,
            label: 'Message',
          },
          name: {
            hint: 'Unique name of the source. This name will be displayed in the logs tab.',
            mustBeUnique: 'Source name must be unique.',
          },
          timestamp: {
            cannotContainComma: 'The timestamp cannot contain any commas',
            hint: 'The timestamp key of your log entry.',
            label: 'Timestamp',
          },
          urls: {
            hint: 'URLs to fetch data from. Leave blank to use the ones defined in the first step. Press TAB or ENTER to add a URL.',
            label: 'URLs',
            mustHaveScheme: 'URLs must begin with either http:// or https://',
            required: `Urls are required as you haven't defined global ones in the first step.`,
          },
        },
        section: {
          mapping: {
            help: 'Map the keys you use on Elastic Search',
            title: 'Mapping',
          },
          prefilters: {
            help: 'Meant to be used when the same index has different sources',
            title: 'Prefilters',
          },
        },
      },
      es: {
        deleteSource: 'Eliminar fuente',
        field: {
          host: {
            cannotContainComma: 'El host no puede contener ninguna coma',
            hint: 'La clave de host de su entrada de log.',
            label: 'Host',
          },
          index: {
            hint: 'Nombre del índice y eventualmente el tipo. p.ej. app-logs o app-logs/type-logs',
            label: 'Índex',
          },
          key: {
            mustBeUnique: 'Esta clave ya existe',
          },

          message: {
            hint: 'La clave de mensaje de su entrada de log, puede estar compuesto por una o varias claves separadas por comas. Por ejemplo: \'message,status\'',
            label: 'Mensaje',
          },
          name: {
            hint: 'Nombre único de la fuente. Este nombre se mostrará en la pestaña de logs.',
            mustBeUnique: 'El nombre de la fuente debe ser único.',
          },
          timestamp: {
            cannotContainComma: 'El timestamp no puede contener ninguna coma',
            hint: 'La clave de timestamp de su entrada de log.',
            label: 'Timestamp',
          },
          urls: {
            hint: 'URLs para obtener datos. Déjelo vacío para usar los definidos en el primer paso. Presione TAB o ENTER para agregar una URL.',
            label: 'URLs',
            mustHaveScheme: 'Las URLs deben comenzar con http:// o https://',
            required: 'Las URLs son necesarias ya que no ha definido las globales en el primer paso.',
          },
        },
        section: {
          mapping: {
            help: 'Asigne las claves que usa en Elastic Search',
            title: 'Mapping',
          },
          prefilters: {
            help: 'Se debe usar cuando el mismo índice tiene diferentes fuentes',
            title: 'Prefiltros',
          },
        },
      },
      fr: {
        deleteSource: 'Supprimer la source',
        field: {
          host: {
            cannotContainComma: `L'hôte ne peut contenir aucune virgule`,
            hint: 'La clé hôte de vos logs.',
            label: 'Hôte',
          },
          index: {
            hint: `Nom de l'index et eventuellement le type. ex: app-logs ou app-logs/type-logs`,
            label: 'Index',
          },
          key: {
            mustBeUnique: 'Cette clé existe déjà',
          },
          message: {
            hint: `La clé message de vos logs, peut être composé d'une ou plusieurs clés séparées par des virgules. ex: 'message,status'`,
            label: 'Message',
          },
          name: {
            hint: `Nom unique de la source. Ce nom sera affiché dans l'onglet logs.`,
            mustBeUnique: 'Le nom de la source doit être unique.',
          },
          timestamp: {
            cannotContainComma: 'Le timestamp ne peut contenir aucune virgule',
            hint: 'La clé timestamp de vos logs.',
            label: 'Timestamp',
          },
          urls: {
            hint: 'URL desquelles récupérer les données. Laissez vide pour utiliser celles définies à la première étape. Appuyez sur TAB ou ENTRÉE pour ajouter une URL.',
            label: 'URLs',
            mustHaveScheme: 'Les URL doivent commencer par http:// ou https://',
            required: `Les URLs sont requises si vous n'en définissez pas globalement à la première étape.`,
          },
        },
        section: {
          mapping: {
            help: 'Mappez les clés que vous utilisez dans Elastic Search',
            title: 'Mapping',
          },
          prefilters: {
            help: 'Destinés à être utilisés lorsque le même index a des sources différentes',
            title: 'Préfiltres',
          },
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.source {
  padding: 24px;
  border: 1px solid get-color("grey", "light-1");
  border-radius: 4px;
  background-color: get-color("grey", "light-4");

  & + & {
    margin-top: 16px;
  }

  > * {
    max-width: 450px;
  }
}

.fieldset {
  margin: 24px 0 0;
  border: none;

  &__title {
    display: block;
    font-size: 16px;
    font-weight: 600;
  }

  &__help-text {
    margin: 4px 0;
    color: get-color("grey", "dark-2");
    font-size: 14px;
  }
}
</style>
